angular.module('apruve.sellers.invoice').controller("InvoicesController", [
  '$scope', '$state', '$stateParams', '$window', 'statusIconsService', 'Invoice', 'currentUserService', '$http',
  function($scope, $state, $stateParams, $window, statusIconsService, Invoice, currentUserService, $http) {
    var _this = this;
    this.scoped_account = !!$stateParams.scoped_account;
    this.statusIconsService = statusIconsService;
    this.new_params = angular.copy($stateParams);
    this.new_params.page = parseInt(this.new_params.page);
    this.url_params = angular.copy(this.new_params);
    this.search_field = this.new_params.search;
    this.url = "/sellers/".concat($stateParams.merchant_id, "/invoices.json");
    this.start_date = this.new_params.start_date ? this.new_params.start_date : null;
    this.end_date = this.new_params.end_date ? new this.new_params.end_date : null;

    this.searchByDate = () => {
      this.new_params = {
        ...this.new_params,
        start_date: this.start_date,
        end_date: this.end_date,
      };

      return $state.go(".", this.new_params, { reload: true });
    };

    this.downloadCsv = () => {
      const startDateParam = _this.new_params.start_date ? `start_date=${_this.new_params.start_date}` : "";
      const endDateParam = _this.new_params.end_date ? `end_date=${_this.new_params.end_date}` : "";
      const params = endDateParam === "" ? `${startDateParam}` : `${startDateParam}&${endDateParam}`
      $http({method: 'GET', url: `/sellers/${$state.params.merchant_id}/invoices.csv?${params}`})
          .success(() => {
            return $state.go(".", _this.new_params, { reload: true });
          })
    };

    this.import_invoice_integrations = function() {
      return currentUserService.currentContext.import_invoice_integrations;
    };

    this.navigate_to_invoice = function(invoice) {
      return $state.go('sellers.invoice', {invoice_uuid: invoice.uuid, merchant_id: $stateParams.merchant_id});
    };

    this.search = function() {
      _this.new_params.page = 0;
      return _this.new_params.search = _this.search_field;
    };

    this.reset_search = function() {
      _this.search_field = '';
      return _this.search();
    };

    this.resort = function(clicked_on_col) {
      if (this.new_params.sort_column !== clicked_on_col) {
        this.new_params.sort_column = clicked_on_col;
        return this.new_params.sort_order = 'asc';
      } else {
        if (this.new_params.sort_order === 'desc') {
          return this.new_params.sort_order = 'asc';
        } else {
          return this.new_params.sort_order = 'desc';
        }
      }
    };

    this.sort_classes_for = function(column) {
      if (column === this.new_params.sort_column) {
        return ['apruve-icon-angle', this.new_params.sort_order];
      } else {
        return [];
      }
    };

    this.row_classes_for = function(invoice) {
      var classes = [];
      if (['closed', 'canceled', 'funded'].includes(invoice.status)) {
        classes.push('list-item-done');
      }
      return classes;
    };

    this.is_overdue = function(invoice) {
      return invoice.status_display === 'overdue';
    };

    this.is_due = function(invoice) {
      return invoice.status_display === 'due soon';
    };

    this.invoices_status = function() {
      if (this.new_params.status === 'open') {
        return 'Open';
      } else {
        return 'All';
      }
    };

    this.import_success = function(data) {
      return $state.go('sellers.invoice', {invoice_uuid: data.id, merchant_id: $stateParams.merchant_id});
    };

    this.import_invoice = function(invoice_number, integration_name) {
      return Invoice.import_external({
        merchant_id: $stateParams.merchant_id,
        invoice_number: invoice_number,
        integration_name: integration_name
      }).$promise;
    };

    this.load_new_page = function(new_value, old_value) {
      if ((new_value == null) || (old_value == null)) { return true; }
      if (new_value === old_value) { return true; }
      $state.go('.', new_value, {notify: false});
      if (new_value.page !== old_value.page) { return true; }
      return _this.url_params = angular.copy(new_value);
    };

    $scope.$watch("invoicesCtrl.new_params", this.load_new_page, true);
  }
]);